@media (max-width: 1024px) {
    .mb-global-header-container, .mb-booking-info {
        display: none !important;
        visibility: hidden;
    }

    .mb-global-header-mobile-wrapper {
        display: block !important;
    }
}

@media (max-width: 768px) and (min-width: 600px) {
    .mb-menu div.mb-booking-mobile-wrapper {
        padding-left: 30px;
        padding-right: 30px;
    }

    .mb-button-cta {
        max-width: 300px;
        margin: auto;
    }
}


@media (min-width: 1025px) {
    .mb-global-header-container, .mb-booking-info {
        display: flex !important;
    }
    .mb-booking-info {
        display: block !important;
    }

    .mb-global-header-mobile-wrapper {
        display: none !important;
        visibility: hidden;
    }
}
@media (min-width: 1025px) and (max-width: 1280px) {
    .mb-header-right-container {
        margin-right: 30px !important;
    }
    .mb-booking-info {
        padding: 15px 30px !important;
    }
    .booking-wrapper {
        gap: 25px !important; 
    }
}
.mb-header-right-container {
    margin-right: 60px;
}
.mb-alerts-container {
    background-color: var(--neutral-white);
    font-family: var(--proxima-nova-adobe);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-regular);
}
.mb-alerts-container .mb-alert-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    background: #FCE2CB;
    height: 48px;
}
.mb-alerts-container .mb-alert-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    gap: 6px;
    text-align: center;
}
.mb-alerts-container .mb-header-alert {
    width: 15px;
    height: 15px;
    margin: 0 1em;
}
.mb-alerts-container .mb-alert-item a {
    color: inherit;
}
.mb-alerts-container .mb-alert-close {
    justify-content: center;
    display: flex;
    cursor: pointer;
    margin: 0 10px;
}

@keyframes alert-show-hide {
    0% {
        top: -70px;
        opacity: 0;
    }
    10%, 90% {
        top: 0px;
        opacity: 1;
    }
    100% {
        top: -70px;
        opacity: 0;
    }
}

.mb-alerts-container .alert-animation {
    animation-name: alert-show-hide;
    animation-duration: 6.5s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    position: relative;
}
.mb-global-header-wrapper {
    background-color: var(--neutral-white);
    font-family: var(--proxima-nova-adobe);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-regular);
    width: 100%;
    z-index: 9999;
    transition: all 0.3s ease;
}
.mb-global-header-wrapper.fixed {
    max-width: 1700px;
    position: fixed;
    top: 0;
}
.mb-global-header-wrapper .pcl-logo {
    display: block;
}
.mb-global-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mb-global-header-container .mb-header-icon {
    width: 20px;
    height: 20px;
}
.mb-header-list {
    display: flex;
    justify-content: center;
    gap: 24px;
    align-items: center;
    list-style: none;
    padding: 0;
}
.mb-header-list-item .mb-header-link {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.mb-header-link:focus {
    box-shadow: none !important;
    outline: none !important;
}
.mb-header-list-item .mb-header-link-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--primary-dark);
}
.mb-header-dropdown {
    position: relative;
    display: inline-block;
}
li.mb-header-dropdown {
    overflow: visible;
}
.mb-global-header-wrapper button {
    cursor: pointer;
    border: 0;
    background: 0;
    margin: 0;
    padding: 0;
}
.mb-header-dropdown .mb-dropdown-list {
    position: absolute;
    background-color: var(--sand-light-700);
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    z-index: 2;
    padding: 10px 20px 20px 20px;
    list-style: none;
    text-align: left;
    right: 0;
    margin-top: 5px;
    min-width: 250px;
    white-space: normal;
}
.mb-header-dropdown .dropdown-list-item {
    text-decoration: none;
    padding: 16px;
}
.mb-header-dropdown .dropdown-list-item.deactive {
    background-color: transparent;
}
.mb-header-dropdown .dropdown-list-item-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: var(--primary-dark);
}
.mb-header-dropdown .mb-register {
    padding: 16px 0 24px 0;
}
.mb-header-dropdown .mb-register a {
    color: inherit;
}
.mb-header-dropdown .captain-circle {
    margin-left: 5px;
}
.mb-header-dropdown .dropdown-btn:after {
    content: "";
    border: 0;
    height: 20px;
    width: 20px;
    background: url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.80292 6.1352C5.09582 5.8423 5.57069 5.8423 5.86358 6.1352L7.99992 8.27153L10.1363 6.1352C10.4291 5.8423 10.904 5.8423 11.1969 6.1352C11.4898 6.42809 11.4898 6.90296 11.1969 7.19586L8.53025 9.86252C8.23736 10.1554 7.76248 10.1554 7.46959 9.86252L4.80292 7.19586C4.51003 6.90296 4.51003 6.42809 4.80292 6.1352Z" fill="%23020215"/></svg>');
    background-position: center;
    vertical-align: middle;
}
.mb-header-dropdown.show .dropdown-btn:after {
    transform: rotate(180deg);
}
.mb-header-dropdown.show .dropdown-content{
    display: block !important;
}
.mb-header-dropdown .dropdown-content{
    display: none;
}
.mb-booking-info {
    padding: 15px;
    border-bottom: 1px solid var(--steel-grey-light-200);
    background-color: var(--neutral-white);
    font-family: var(--proxima-nova-adobe);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-regular);
}
.mb-booking-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 65px;
}
.booking-wrapper {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    gap: 45px;
}
.booking-wrapper > hr {
    width: 1px;
    height: 120px;
    background-color: var(--steel-grey-light-200);
    border: none;
    margin: 0 10px;
}
.mb-booking-detail{
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 1em 0;
}
.mb-label {
    color: var(--steel-grey);
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 8px;
    font-weight: 500;
    line-height: 20px;
}
.mb-value {
    color: var(--primary-dark);
    font-size: 25px;
    font-weight: 400;
    line-height: 35px;
}
.mb-minor-booking-link {
    font-size: 18px;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}
.mb-subtext {
    color: var(--steel-grey);
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}
.mb-btn-cta {
    display: flex;
    align-items: center;
}
.mb-btn-blue {
    background: var(--primary-cta);
    color: var(--neutral-white);
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.6px;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;
    height: 52px;
    width: 100%;
    padding: 16px 24px;
    display: flex;
    white-space: nowrap;
    align-items: center;
}
.mb-btn-blue:hover, .mb-btn-blue:focus {
    background: var(--primary-cta-hover);
}
.mb-btn-cta  .btn-txt {
    margin: 0 auto;
}
.mb-btn-blue-outline {
    background-color: var(--neutral-white);
    color: var(--primary-dark);
    border-radius: 4px;
    border: 3px solid var(--primary-blue);;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.6px;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;
    height: 45px;
    width: 100%;
    padding: 16px 32px;
    display: flex;
    white-space: nowrap;
    align-items: center;
}
.mb-pink-btn:hover {
    background-color: var(--accent-pink);
}
.button.pv-btn.disabled {
    background-image: none;
    pointer-events: none;
}
.mb-global-header-mobile-wrapper .mb-mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mb-global-header-mobile-wrapper .mb-menu {
    padding: 0 1em;
}
.mb-menu .header-menu-container {
    position: fixed;
    top: 70px;
    right: 0;
    height: 100%;
    max-height: calc(100vh - 75px);
    width: 100vw;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    z-index: 1030;
    background-color: #fff;
    overflow-y: scroll;
    height: 100%;
    scrollbar-width: none;
}
.mb-menu.show .dropdown-content{
    transform: translateX(0);
    overflow-y: scroll;
    height: 100%;
    scrollbar-width: none;
}
.mb-menu-text {
    color: var(--primary-dark);
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    padding-top: 5px;
}
.mb-mobile-header .mb-menu-icon {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='25'%20height='16'%20viewBox='0%200%2025%2016'%20fill='none'%3E%3Cpath%20d='M24.8467%208.47656H0.84668V7.47656H24.8467V8.47656Z'%20fill='%23020215'/%3E%3Cpath%20d='M24.8467%201.45703H0.84668V0.457031H24.8467V1.45703Z'%20fill='%23020215'/%3E%3Cpath%20d='M24.8467%2015.5H0.84668V14.5H24.8467V15.5Z'%20fill='%23020215'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 25px;
    height: 16px;
}
.mb-mobile-header.show .mb-menu-icon {
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.43539 7.25485L14.5765 1.11373L13.9227 0.459961L7.78161 6.60108L1.77185 0.591313L1.11808 1.24509L7.12784 7.25485L0.84729 13.5354L1.50106 14.1892L7.78161 7.90862L14.1935 14.3205L14.8473 13.6668L8.43539 7.25485Z" fill="%23020215"/></svg>');
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 15px;
    height: 15px;
}
.mb-menu .mb-booking-mobile-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 20px;
}
.mb-booking-info-mobile {
    display: flex;
    justify-content: space-between;
}
.mb-menu .mb-booking-detail {
    padding: 0;
}
.mb-menu .mb-booking-info-mobile {
    display: flex;
    justify-content: space-between;
}
.mb-menu .mb-header-container-mobile {
    background-color: var(--sand-light-700);
}
.mb-menu .mb-header-list-mobile {
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

@media only screen and (max-width: 1024px) {
    .mb-label {
        font-size: 16px;
        margin: 0;
    }
    .mb-value {
        font-size: 22px;
    }
    .mb-subtext {
        font-size: 16px;
    }
    .mb-payment-action {
        padding-top: 20px;
    }
    .mb-btn-pink {
        padding: 16px 32px;
    }
    .mb-booking-mobile-wrapper hr {
        margin: 0;
    }
    .mb-menu-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 1em !important;
    }
    .mb-header-dropdown .dropdown-list-item {
        padding: 0px;
    }
    .mb-header-dropdown .mb-dropdown-wrapper {
        display: flex;
        flex-direction: column;
        gap: 28px;
        padding-top: 28px;
    }
    .mb-header-dropdown .dropdown-list-item-text {
        font-size: 16px;
        text-align: center;
    }
    .mb-header-dropdown .mb-header-icon {
        width: 20px;
        height: 20px;
    }
    .mb-header-dropdown .mb-register {
        padding: 28px;
    }
    .mb-minor-booking-link {
        font-size: 16px;
        line-height: 24px;
    }
}
